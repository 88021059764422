import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.brandName === 'Samsung')?_c('div',{staticClass:"shadowcontainer"},[_c('div',{attrs:{"id":"shadow"}},[_c(VCard,[_c(VCol,{staticClass:"pa-5",attrs:{"cols":"12"}},[_c(VCardText,{staticClass:"pt-10 px-6 pb-6 fill-height"},[_c(VRow,{staticClass:"mx-0 fill-height",attrs:{"align-content":"space-between","justify":"center"}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"11"}},[(_vm.imagePath)?_c(VImg,{staticClass:"mx-auto mb-6",attrs:{"src":(_vm.publicPath + "public_storage/" + _vm.imagePath),"height":"200","width":"200"}}):_c(VImg,{staticClass:"mx-auto mb-6",attrs:{"src":(_vm.publicPath + "images/no-image.png"),"height":"200","width":"200"}})],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"primary",on:{"click":_vm.gotoBrandHomePage}},[_vm._v(" "+_vm._s(_vm.$t('button.select'))+" ")])],1)],1)],1)],1)],1)],1)]):(_vm.brandName !== 'Samsung')?_c('div',[_c('div',[_c(VCard,{attrs:{"height":"345","max-width":"280"}},[_c(VCardText,{staticClass:"pt-10 px-6 pb-6 fill-height"},[_c(VRow,{staticClass:"mx-0 fill-height",attrs:{"align-content":"space-between","justify":"center"}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[(_vm.imagePath)?_c(VImg,{staticClass:"mx-auto mb-6",attrs:{"src":(_vm.publicPath + "public_storage/" + _vm.imagePath),"height":"120","width":"120"}}):_c(VImg,{staticClass:"mx-auto mb-6",attrs:{"src":(_vm.publicPath + "images/no-image.png"),"height":"120","width":"120"}}),_c('h2',{staticClass:"title text-center black--text mb-5"},[_vm._v(_vm._s(_vm.brandName))])],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"primary",on:{"click":_vm.gotoBrandHomePage}},[_vm._v(" "+_vm._s(_vm.$t('button.select'))+" ")])],1)],1)],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }