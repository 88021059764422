<template>
  <v-container>
    <v-row justify="center">

      <template v-for="(item, index) in listThisItems" >
        <v-col cols="11" :key="index" v-if="item.webVisible === 1 && item.brandName === 'Samsung'">
          <brand-list-card :brand-name="item.brandName"
                           :image-path="item.imagePath"
                           :brand-id="item.brandId"></brand-list-card>
        </v-col>
      </template>
      <template v-for="(item, index) in listThisItems">
        <v-col cols="auto" :key="index" v-if="item.webVisible === 1 && item.brandName !== 'Samsung'">
          <brand-list-card :brand-name="item.brandName"
                           :image-path="item.imagePath"
                           :brand-id="item.brandId"></brand-list-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import BrandListCard from "./BrandListCard.vue";

export default {
  name: 'ListBrandCards',
  components: {
    BrandListCard,
  },
  props: {
    listThisItems: Array,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style>
#background {
  position: absolute;
  background: #2a5298;
}

</style>
