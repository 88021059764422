<template>
  <div v-frag>
    <hero-title title="pickUpAndReturn" subtitle="services.simpleRepair" subtitle2="services.simpleRepairs"></hero-title>
    <list-brand-cards :list-this-items="brands"></list-brand-cards>
  </div>
</template>

<script>
import HeroTitle from "../../../components/titles/HeroTitle.vue";
import ListBrandCards from "../../../components/services/ListBrandCards.vue";

export default {
  name: 'ExternalBrandsPage',
  components: {
    ListBrandCards,
    HeroTitle,
  },
  props: {
    //
  },
  async beforeMount() {
    this.brands = await this.callListAllBrands();
  },
  data() {
    return {
      brands: [],
    };
  },
  methods: {
    async callListAllBrands() {
      return await this.$store.dispatch('listAllBrands');
    },
  },
};
</script>

<style scoped>

</style>
