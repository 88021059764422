<template>
  <div v-if="brandName === 'Samsung'" class="shadowcontainer">
    <div  id="shadow">
      <v-card>
        <v-col class="pa-5" cols="12">
          <v-card-text class="pt-10 px-6 pb-6 fill-height">
            <v-row class="mx-0 fill-height" align-content="space-between" justify="center">
              <v-col cols="11" class="pa-0">
                <v-img v-if="imagePath" :src="`${publicPath}public_storage/${imagePath}`" height="200" width="200" class="mx-auto mb-6"></v-img>
                <v-img v-else :src="`${publicPath}images/no-image.png`" height="200" width="200" class="mx-auto mb-6"></v-img>
              </v-col>
              <v-col cols="auto" class="pa-0">
                <v-btn class="primary" @click="gotoBrandHomePage">
                  {{ $t('button.select') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-card>
    </div>
  </div>
  <div v-else-if="brandName !== 'Samsung'">
    <div >
      <v-card height="345" max-width="280" >
        <v-card-text class="pt-10 px-6 pb-6 fill-height">
          <v-row class="mx-0 fill-height" align-content="space-between" justify="center">
            <v-col cols="12" class="pa-0">
              <v-img v-if="imagePath" :src="`${publicPath}public_storage/${imagePath}`" height="120" width="120" class="mx-auto mb-6"></v-img>
              <v-img v-else :src="`${publicPath}images/no-image.png`" height="120" width="120" class="mx-auto mb-6"></v-img>
              <h2 class="title text-center black--text mb-5">{{ brandName }}</h2>
            </v-col>
            <v-col cols="auto" class="pa-0">
              <v-btn class="primary" @click="gotoBrandHomePage">
                {{ $t('button.select') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandListCard',
  components: {
    //
  },
  props: {
    brandName: String,
    imagePath: String,
    brandId: Number,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    gotoBrandHomePage() {
      const brandPathName = `${this.brandName.toLowerCase().split(' ').join('')}Home`;
      this.$router.push({ name: brandPathName });
    },
  },
};
</script>

<style>
:root{
  --gradient-shadow: linear-gradient(
    #4A89DC,
    rgba(74, 137, 220, 0.69)
  );
}

#shadow {
  position: relative;
  transform-style: preserve-3d;

}
#shadow::after,
#shadow::before {
  content: "";
  position: absolute;
  inset: -3px;
  background: var(--gradient-shadow);
  filter: blur(5px);
  transform: translate3d(-1px,0px,-1px);
  border-radius: inherit;
  pointer-events: none;
}
#shadow:after {
  filter: blur(10px);
}
</style>
